import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "./auth-header";
import { Link } from "react-router-dom";
import { ChevronRightIcon, ExclamationCircleIcon, SignalIcon } from '@heroicons/react/20/solid';

export default function ListetousVHL() {
  const [vehicles, setVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [searchValueGarage, setSearchValueGarage] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [RDVvhl, setRDVVhl] = useState([]);


  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
            .get(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/", { headers: authHeader() })
            .then((response) => {
              setRDVVhl(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
  }, []);
  
  const listStyles = {
    width: "100%",
    maxWidth: "100%",
    bgcolor: "background.paper",
    marginTop: "-25px",
  };

  const handleSearchGarage = (e) => {
    setSearchValueGarage(e.target.value);
  };

  const filteredVehiclesGarage = vehicles.filter((vehicle) => {
    if (searchValueGarage === "") {
      return vehicle;
    } else {
      return (
        (vehicle.nomvhl && vehicle.nomvhl.toLowerCase().includes(searchValueGarage.toLowerCase())) ||
        (vehicle.dossardorigine && vehicle.dossardorigine.toLowerCase().includes(searchValueGarage.toLowerCase()))
      );
    }
  });

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredVehicles = vehicles.filter((vehicle) => {
    if (searchValue === "") {
      return vehicle && currentUser && vehicle.service === currentUser.service;
    } else {
      return (
        (vehicle && currentUser && vehicle.service === currentUser.service) &&
        ((vehicle && currentUser && vehicle.nomvhl && vehicle.nomvhl.toLowerCase().includes(searchValue.toLowerCase())) ||
        (vehicle && currentUser && vehicle.dossardorigine && vehicle.dossardorigine.toLowerCase().includes(searchValue.toLowerCase())))
      );
    }
  });
  
  // Trier les véhicules par ordre alphabétique du nomvhl
  filteredVehicles.sort((a, b) => a.nomvhl.localeCompare(b.nomvhl));
  
  const groupedVehicles = {};
  
  filteredVehicles.forEach((vhl) => {
    if (!groupedVehicles[vhl.typedevhl]) {
      groupedVehicles[vhl.typedevhl] = [];
    }
    groupedVehicles[vhl.typedevhl].push(vhl);
  });
  
  const groupedVehiclesGarage = {};
  
  filteredVehiclesGarage.forEach((vhl) => {
    if (!groupedVehiclesGarage[vhl.service]) {
      groupedVehiclesGarage[vhl.service] = [];
    }
    groupedVehiclesGarage[vhl.service].push(vhl);
  });
  

  return (
<div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 10px 16px",
          position: "relative",
        }}
      >
        {currentUser.service === "GARAGE" ? (
          <div className="relative">
            <label
              htmlFor="rechercher"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              Rechercher
            </label>
            <input
              type="text"
              name="rechercher"
              id="rechercher"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
              value={searchValueGarage}
              onChange={handleSearchGarage}
            />
          </div>
        ) : (
          <div className="relative">
            <label
              htmlFor="rechercher"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              Rechercher
            </label>
            <input
              type="text"
              name="rechercher"
              id="rechercher"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
              value={searchValue}
              onChange={handleSearch}
            />
          </div>
        )}
      </div>

      {currentUser.service === "GARAGE" ? (
        <div>
          <h3 className="text-2xl font-bold text-center mb-6">Tous les véhicules</h3>
          <nav className="h-full overflow-y-auto" aria-label="Directory">
            {Object.keys(groupedVehiclesGarage).map((service) => (
              <div key={service} className="relative">
                <div className="text-center sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
                  <h3>{service}</h3>
                </div>
                <ul role="list" className="divide-y divide-gray-100">
                  {groupedVehiclesGarage[service].map((vhl) => (
                    <Link to={`/pagevhl/${vhl._id}`} className="block" key={vhl._id}>
                      <div className="flex gap-x-4 px-3 py-5">
                        <img className="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover" src={vhl.image} alt="" />
                        <div className="min-w-0 flex-1 font-semibold flex items-center justify between">
                          <div>
                            <p className="text-base leading-6 text-gray-900">{vhl.nomvhl}</p>
                            <p className="mt-1 text-sm italic leading-5 text-gray-500">{vhl.dossardorigine}</p>
                          </div>
                          <div className="ml-10 text-sm italic leading-5 text-gray-500">{vhl.position}</div>
                        </div>
                        <ChevronRightIcon className="self-center h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    </Link>
                  ))}
                </ul>
              </div>
            ))}
          </nav>
        </div>
      ) : (
        <div>
          <h3 className="text-2xl font-bold text-center mb-6">Liste des véhicules</h3>
          <nav className="h-full overflow-y-auto" aria-label="Directory">
            {Object.keys(groupedVehicles).map((typedevhl) => (
              <div key={typedevhl} className="relative">
                <div className="text-center sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
                  <h3>{typedevhl}</h3>
                </div>
                <ul role="list" className="divide-y divide-gray-100">
                  {groupedVehicles[typedevhl].map((vhl) => (
                    <Link to={`/pagevhl/${vhl._id}`} className="block" key={vhl._id}>
                      <div className="flex gap-x-4 px-3 py-5">
                        <img className="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover" src={vhl.image} alt="" />
                        <div className="min-w-0 flex-1">
                        <p className="text-base font-semibold leading-6 text-gray-900 flex items-center">
                          <span>{vhl.nomvhl}</span>
                          {vhl.majradio === "DMR" && (
                            <div className="ml-2">
                              <SignalIcon className="h-5 w-5 text-blue-700" aria-hidden="true" />
                            </div>
                          )}
                        </p>
                          <p className="mt-1 truncate text-sm italic leading-5 text-gray-500">{vhl.dossardorigine}</p>
                        </div>
                        {RDVvhl.some(rdv => rdv.idvhl === vhl._id) && (
                            <div className=" absolute block ">
                              <div className="flex-shrink-0">
                                <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                              </div>
                            </div>
                        )}
                        <ChevronRightIcon className="self-center h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    </Link>
                  ))}
                </ul>
              </div>
            ))}
          </nav>
        </div>
      )}
    </div>
  );
}
