import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import "react-datepicker/dist/react-datepicker.css";
import authHeader from "../auth-header";
import Datepicker from "tailwind-datepicker-react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function NewRevision() {
  const [vhl, setVhl] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState(new Date());
  const [kmeffectif, setKmEffectif] = useState("");
  const [kmrevision, setKmRevision] = useState("");
  const [remarque, setRemarque] = useState("");
  const { id } = useParams();
  const [show, setShow] = useState(false);

  const revisions = [];
  for (let km = 10000; km <= 300000; km += 10000) {
    revisions.push({ title: `Révision des ${km}km`, km });
  }

  const [selectedRevision, setSelectedRevision] = useState(revisions[0]);

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2023-01-01"),
    theme: {
      background: "bg-gray-50 dark:bg-gray-700",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "bg-gray-100"
    },
    icons: {
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12",
    defaultDate: new Date(),
    language: "fr",
    disabledDates: [],
    weekDays: ["Lu", "Mar", "Mer", "Jeu", "Ve", "Sa", "Di"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Selectionner la date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleDateClick = () => {
    setShow(true);
  };

  const onChangeKm = (e) => {
    setKmEffectif(Number(e.target.value));
    };

  const onChangeKMRevision = (e) => {
    setSelectedRevision(e);
    setKmRevision(e.km);
  };

  const onChangeRemarque = (e) => {
    setRemarque(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const historevision = {
      idvhl: vhl._id,
      nomvhl: vhl.nomvhl,
      service: vhl.service,
      date: date,
      remarque: remarque,
      kmeffectif: Number(kmeffectif),
      km: selectedRevision.km,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/historiquerevision/add/", historevision, { headers: authHeader() })
      .then((res) => {
        window.location = `/historiquerevision/${vhl._id}`;
        console.log(historevision);
      })
      .catch((error) => {
        console.log(error);
      });

      
  };

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
        .then((response) => {
          setVhl(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setIsLoading(false);
    }
  }, [id, isLoading]);


  return (
    <div>
      <div className="flex flex-col items-center">
    <img
      className="h-40 w-40 rounded-full object-cover mb-3"
      src={vhl.image}
      alt={vhl.nomvhl}
    />
    <h3 className="text-2xl font-bold text-center">{vhl.nomvhl}</h3>
    <h2 className="text-lg italic text-center mb-6">{vhl.dossardorigine}</h2>
  </div>

  <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-gray-500">
          <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
        </span>
      </div>
    </div>

      <form onSubmit={onSubmit} className="flex flex-col items-center justify-center">

      <div className="mb-2 mt-4">
        <Listbox value={selectedRevision} onChange={onChangeKMRevision}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Révision des ...</Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button
                  className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{selectedRevision.km}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Listbox.Options
  as="ul"
  className="absolute z-10 max-w-xs w-full py-1 text-base bg-white border border-gray-300 divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
>
  {revisions.map((option) => (
    <Listbox.Option
      key={option.id}
      value={option}
    >
      {({ active, selected }) => (
        <li
          onClick={() => setSelectedRevision(option)}
          className={classNames(
            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
            'cursor-pointer select-none relative px-4 py-2'
          )}
        >
          {option.km}
          {selected ? (
            <span
              className={classNames(
                active ? 'text-white' : 'text-indigo-600',
                'absolute inset-y-0 left-0 flex items-center pl-3'
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          ) : null}
        </li>
      )}
    </Listbox.Option>
  ))}
</Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        </div>


      <div className="mt-5">
			<Datepicker 
      options={options} 
      value={date} 
      onChange={(date) => handleDateChange(date)} 
      show={show} 
      setShow={setShow} 
      onClick={handleDateClick}
      />
		</div>

    <div className="relative mt-5">
      <label
        htmlFor="km"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Kilométrage
      </label>
      <input
        type="number"
        name="kmeffectif"
        id="kmeffectif"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={kmeffectif}
        onChange={onChangeKm}
      />
    </div>

    <div className="relative mt-5">
      <label
        htmlFor="nom"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Remarques
      </label>
      <input
        type="text"
        name="remarques"
        id="remarques"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={remarque}
        onChange={onChangeRemarque}
      />
    </div>

    <div className="form-group text-center">
        <button
    type="submit"
    className="mt-8 mx-auto rounded-lg bg-white border-2 border-indigo-900 px-3 py-2 text-base font-semibold text-indigo-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
  >
    Valider
  </button>
        </div>
      </form>
    </div>
  );
}

export default NewRevision;
