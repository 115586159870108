import React, { useEffect, useState, Fragment, useRef } from 'react';
import axios from 'axios';
import { Dialog, Transition, Listbox } from '@headlessui/react';
import { useParams } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { ArrowRightCircleIcon, TrashIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import Datepicker from "tailwind-datepicker-react";
import authHeader from "../auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function DetailDmdGarage(props) {
  const [vhl, setVhl] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  
  const [typerdv, setTypeRDV] = useState("");
  const [selectedRDV, setselectedRDV] = useState("");
  const [remarque, setRemarque] = useState("");
  const [daterdv, setDateRDV] = useState("");
  const [show, setShow] = useState(false);

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2023-01-01"),
    theme: {
      background: "bg-gray-50 dark:bg-gray-700",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "bg-gray-100"
    },
    icons: {
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12 w-64",
    defaultDate: new Date(),
    language: "fr",
    disabledDates: [],
    weekDays: ["L", "M", "M", "J", "V", "S", "D"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Selectionner la date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };

  const handleDateClick = () => {
    setShow(true);
  };
  
  const handleChangeTypeRDV = (value) => {
    setTypeRDV(value.name);
    setselectedRDV(value);
  };

  const handleChangeRemarque = (e) => {
    setRemarque(e.target.value);
  };

  const onChangeDateRDV = (date) => {
    setDateRDV(date);
};

  const { id } = useParams();

  const typeRDVOptions = [
    { id: 1, name: 'Check VHL' },
    { id: 2, name: 'Visite' },
    { id: 3, name: 'Accident' },
    { id: 4, name: 'Confection de plaques' },
    { id: 5, name: 'Liquide de frein' },
    { id: 6, name: 'Pneumatique' },
    { id: 7, name: 'Frein' },
    { id: 8, name: 'Distribution' },
    { id: 9, name: 'Embrayage' },
    { id: 10, name: 'Recherche de panne' },
    { id: 11, name: 'Récupération de VHL' },
    { id: 12, name: 'Autre' },
  ];

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/' + id, { headers: authHeader() })
        .then(response => {
          setVhl({
            ...response.data,
          });
        })
        .catch(error => {
          console.log(error);
        });

      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
        .then(response => {
          setCurrentUser(response.data);
        })
        .catch(error => {
          console.log(error);
        });

      setisLoading(false);
    }
  }, [id, isLoading]);

  const deleteDmd = (id) => {
    axios
      .delete(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/' + id, { headers: authHeader() })
      .then((res) => {
        window.location = '/';
      })
    setisLoading(true);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const rdv = {
      idvhl: vhl._id,
      nomvhl: vhl.nomvhl,
      service: vhl.service,
      dossardorigine: vhl.dossardorigine,
      daterdv,
      remarque,
      image: vhl.image,
      typerdv,
      idusers: currentUser.username,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/add", rdv, {
        headers: authHeader(),
      })
      .then((res) => {
        //window.location = "/rdvvhl";
      })
      .catch((error) => {
        console.error(error);
      }); 
  };

  return (
          <div>
          <div className="flex flex-col items-center">
        <img
          className="h-40 w-40 rounded-full object-cover mb-3"
          src={vhl.image}
          alt={vhl.nomvhl}
        />
        </div>
        <h3 className="text-2xl font-bold text-center mb-2">{vhl.nomvhl}</h3>
        <h2 className="text-lg italic text-center mb-6">{vhl.dossardorigine}</h2>

        <div className="flex justify-center items-center mb-8">
        <span className="inline-flex items-center rounded-full bg-red-50 px-4 py-2 text-lg font-semibold text-red-700 ring-1 ring-inset ring-red-600/10">
          {vhl.typedemande}
        </span>
      </div>


        <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">Dégré d'urgence</dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
      {vhl.degreurgence}
      </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">Kilométrage</dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
      {vhl.km}
      </dd>
      </div>
      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">Nom</dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
      {vhl.nom}
      </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">N° de TPH</dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
      {vhl.nTPH}
      </dd>
      </div>
      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">Position du CDB</dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
      {vhl.positioncdb}
      </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">Position du VHL</dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
      {vhl.positionvhl}
      </dd>
      </div>


      <div>
      <label htmlFor="comment" className="mt-4 ml-8 block text-sm font-semibold leading-6 text-gray-900">
        Détails / Explications
      </label>
      <div className="mt-2">
        <textarea
          rows={4}
          name="comment"
          id="comment"
          disabled
          className=" block w-3/4 ml-8 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={vhl.details}
        />
      </div>
    </div>

    {currentUser.service === "GARAGE" ? (
        <div className="flex justify-center items-center mt-8">
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-green-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus-visible:outline-none"
          >
            <ArrowRightCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            Planifier un RDV
          </button>
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => setOpen(false)}>
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white flex items-center justify-center px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                          <Dialog.Title as="h3" className="text-lg mb-3 leading-6 font-medium text-gray-900">
                            Planifier un RDV
                          </Dialog.Title>
                          <form onSubmit={onSubmit} >
                          <h3 className="text-xl font-bold text-center mb-2">{vhl.nomvhl}</h3>
                          <h2 className="text-lg italic text-center mb-6">{vhl.dossardorigine}</h2>

                          <Listbox value={selectedRDV} onChange={handleChangeTypeRDV}>
                            {({ open }) => (
                              <>
                                <Listbox.Label className="mt-5 block text-sm font-medium leading-6 text-gray-900">Type de RDV</Listbox.Label>
                                <div className="relative mt-2">
                                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-4 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    <span className="block truncate">{selectedRDV.name}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {typeRDVOptions.map((rdv) => (
                                        <Listbox.Option
                                          key={rdv.id}
                                          className={({ active }) =>
                                            classNames(
                                              active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                              'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                          }
                                          value={rdv}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                {rdv.name}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active ? 'text-white' : 'text-indigo-600',
                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                  )}
                                                >
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>

                          <div className="mt-2">
                              <textarea
                                rows={2}
                                name="remarque"
                                id="remarque"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
                                placeholder={'Détails / Explications'}
                                value={remarque}
                                onChange={handleChangeRemarque}
                              />
                            </div>

                            <div className="mt-4 items-center justify-center">
                            <Datepicker 
                              options={options} 
                              value={vhl.daterdv} 
                              onChange={(date) => onChangeDateRDV(date)} 
                              show={show} 
                              setShow={setShow} 
                              onClick={handleDateClick}
                              />
                              </div>

                      <div className="form-group text-center">
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-800 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Valider
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Annuler
                      </button>
                    </div>
                      </div>
                            </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      ) : null}

  {currentUser.roles === "Admin" || currentUser.username === vhl.nom ? (
    <div className="flex justify-center items-center mt-2">
<button
type="button"
onClick={() => {
  if (window.confirm('Êtes-vous sûr de vouloir supprimer cette demande ?')) {
    deleteDmd(vhl._id);
  }
}}
className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-red-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline-none"
>
<TrashIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
Supprimer la demande
</button>
</div>

  ) : (
    ""
  )}
</div> 
  )
}

export default DetailDmdGarage;
