import React, { useEffect, useState } from 'react';
import axios from 'axios';
import authHeader from '../auth-header';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import 'tailwindcss/tailwind.css';
import classNames from 'classnames';
import { format, addMonths, subMonths, startOfMonth, parseISO, getDaysInMonth, getDay, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';

const baseURL = process.env.REACT_APP_BASE_URL;
const currentUserURL = baseURL + '/api/users/CurrentUser';
const planninggarageURL = baseURL + '/api/planninggarage';

export default function CalendrierTachesV2() {
  const [taches, setTaches] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [displayedDate, setDisplayedDate] = useState(new Date()); // The currently displayed month and year
  const [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    axios
      .get(planninggarageURL, { headers: authHeader() })
      .then((response) => {
      const sortedTaches = response.data.sort((a, b) => a.nomgaragiste.localeCompare(b.nomgaragiste));
      setTaches(sortedTaches);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(currentUserURL, { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Function to navigate to the previous month
  const goToPreviousMonth = () => {
    const newDisplayedDate = subMonths(displayedDate, 1);
    setDisplayedDate(newDisplayedDate);
  };

  // Function to navigate to the next month
  const goToNextMonth = () => {
    const newDisplayedDate = addMonths(displayedDate, 1);
    setDisplayedDate(newDisplayedDate);
  };

  // Générer les jours du mois actuel
  const daysInMonth = getDaysInMonth(displayedDate);
  const firstDayOfMonth = getDay(startOfMonth(displayedDate));

  const days = Array.from({ length: daysInMonth }, (_, i) => {
    const currentDate = new Date(displayedDate.getFullYear(), displayedDate.getMonth(), i + 1);
    const isCurrentMonth = isSameDay(currentDate, startOfMonth(displayedDate));
    return { date: currentDate, isCurrentMonth };
  });


  // Filtrer les réunions pour afficher uniquement celles du jour sélectionné
 const tachesForSelectedDay = taches.filter((tache) => {
  return selectedDay ? isSameDay(new Date(tache.date), selectedDay) : true;
});

// Gestion des couleurs VHL
const optionscouleurs = ['Absent', 'En attente', 'Disponible'];
  const [selectedIndex, setSelectedIndex] = useState(0);

  const incrementIndex = (id) => {
    return () => {
      const updatedTaches = [...taches]; // Faites une copie de la liste taches
      const tacheIndex = updatedTaches.findIndex((t) => t._id === id); // Trouvez l'index de la tâche dans la copie
  
      if (tacheIndex !== -1) {
        const index = optionscouleurs.indexOf(updatedTaches[tacheIndex].couleur);
        const nextIndex = (index + 1) % optionscouleurs.length;
        const nextCouleur = optionscouleurs[nextIndex];
        updatedTaches[tacheIndex].couleur = nextCouleur; // Mettez à jour la couleur de la copie
        updateTacheCouleur(id, nextCouleur); // Appelez votre fonction de mise à jour de la couleur
  
        setTaches(updatedTaches); // Mettez à jour la liste taches dans l'état local
      }
    };
  };
  
  const decrementIndex = (id) => {
    return () => {
      const updatedTaches = [...taches]; // Faites une copie de la liste taches
      const tacheIndex = updatedTaches.findIndex((t) => t._id === id); // Trouvez l'index de la tâche dans la copie
  
      if (tacheIndex !== -1) {
        const index = optionscouleurs.indexOf(updatedTaches[tacheIndex].couleur);
        const prevIndex = index === 0 ? optionscouleurs.length - 1 : index - 1;
        const prevCouleur = optionscouleurs[prevIndex];
        updatedTaches[tacheIndex].couleur = prevCouleur; // Mettez à jour la couleur de la copie
        updateTacheCouleur(id, prevCouleur); // Appelez votre fonction de mise à jour de la couleur
  
        setTaches(updatedTaches); // Mettez à jour la liste taches dans l'état local
      }
    };
  };

  function updateTacheCouleur(id, nvllecouleur) {
    
    const data = { couleur: nvllecouleur };

    axios
      .post(baseURL + "/api/planninggarage/updateCouleur/" + id, data, {headers: authHeader()})
      .then((res) => {
      console.log(res.data);
      })
      .catch((error) => {
        console.error('Erreur lors de la mise à jour de la couleur de la tâche :', error);
      });
  }
  

  const badgeStyles = {
    'Disponible': 'bg-green-50 text-green-700 ring-green-600/20',
    'En attente': 'bg-orange-100 text-orange-700 ring-orange-600/20',
    'Absent': 'bg-red-100 text-red-800 ring-red-600/20',
  };

  return (
        <div>
      <h2 className="text-base text-center font-semibold leading-6 text-gray-900">Planning Garage</h2>
      <div className="">
        <div className="max-w-lg mx-auto mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
          <div className="flex items-center text-gray-900">
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={goToPreviousMonth}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold">
              {format(displayedDate, 'LLLL yyyy')}
            </div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={goToNextMonth}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>D</div>
            <div>L</div>
            <div>M</div>
            <div>M</div>
            <div>J</div>
            <div>V</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {Array(firstDayOfMonth)
              .fill(null)
              .map((_, idx) => (
                <button key={`prev-${idx}`} type="button" className="py-1.5"></button>
              ))}
            {days.map((day, dayIdx) => (
              <button
                key={day.date.toISOString()}
                type="button"
                className={classNames(
                  'py-1.5 hover:bg-gray-100 focus:z-10',
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                  (day.isSelected || isSameDay(day.date, selectedDay)) && 'font-semibold',
                  day.isSelected && 'text-white',
                  !day.isSelected && day.isCurrentMonth && !isSameDay(day.date, selectedDay) && 'text-gray-900',
                  !day.isSelected && !day.isCurrentMonth && !isSameDay(day.date, selectedDay) && 'text-gray-400',
                  isSameDay(day.date, new Date()) && !day.isSelected && 'text-indigo-600',
                  dayIdx === 0 && 'rounded-tl-lg',
                  dayIdx === 6 && 'rounded-tr-lg',
                  dayIdx === days.length - 7 && 'rounded-bl-lg',
                  dayIdx === days.length - 1 && 'rounded-br-lg'
                )}
                onClick={() => setSelectedDay(day.date)}
              >
                <time
                  dateTime={day.date.toISOString()}
                  className={classNames(
                    'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                    day.isSelected && isSameDay(day.date, new Date()) && 'bg-indigo-600',
                    day.isSelected && !isSameDay(day.date, new Date()) && 'bg-gray-900'
                  )}
                >
                  {day.date.getDate()}
                </time>
              </button>
            ))}
          </div>
          <button
            type="button"
            onClick={() => (window.location.href = "/newtache")}
            className="mt-8 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Ajouter une tâche
          </button>
        </div>

        <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto flex items-center">
        <h1 className="mt-6 text-base font-semibold leading-6 text-gray-900">Planning du {selectedDay ? format(selectedDay, 'dd LLLL yyyy', { locale: fr }) : ''}</h1>
      </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Nom Garagiste
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Type de travail
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Voiture
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Remarques
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Priorité
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Statut VHL
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Voir</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {tachesForSelectedDay.map((tache) => (
                  <tr key={tache.id}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <td className="whitespace-nowrap px-3 py-5 font-semibold text-sm text-gray-500">{tache.nomgaragiste}</td>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">{tache.typetravail}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div className="flex items-center">
                        <div className="h-11 w-11 flex-shrink-0">
                          <img className="h-10 w-10 flex-none rounded-full bg-gray-50 object-cover" src={tache.image} alt="" />
                        </div>
                        <div className="ml-4">
                        <div className="font-medium text-gray-900">{tache.service}</div>
                          <div className="font-semibold text-gray-900">{tache.nomvhl}</div>
                          <div className="mt-1 text-gray-500">{tache.dossardorigine}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div className="text-gray-900">{tache.remarques}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div className="text-gray-900">{tache.priorite}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="flex items-center justify-center">
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${badgeStyles[tache.couleur]}`}>
                          {tache.couleur}
                        </span>
                        <div className='ml-3 flex flex-col'>
                        <button onClick={decrementIndex(tache._id)}>↑</button>
                        <button onClick={incrementIndex(tache._id)}>↓</button>
                        </div>
                      </div>
                    </td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <a href={`/planninggarage/${tache._id}`} className="text-indigo-600 hover:text-indigo-900">
                        Voir<span className="sr-only">, {tache.typetravail}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  );
}
