import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import authHeader from "./auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DmdGarage = () => {
  const [idvhl, setIdVHL] = useState("");
  const [nomvhl, setVHL] = useState("");
  const [service, setService] = useState("");
  const [dossardorigine, setImmat] = useState("");
  const [km, setKm] = useState("");
  const [image, setImage] = useState("");
  const [typedemande, setTypeDmd] = useState("");
  const [degreurgence, setDegreUrgence] = useState("");
  const [nom, setNom] = useState("");
  const [nTPH, setNTPH] = useState("");
  const [positioncdb, setPositionCDB] = useState("");
  const [positionvhl, setPositionVHL] = useState("");
  const [details, setDetails] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [selectedVHL, setSelectedVHL] = useState({});
  const [selectedDMD, setSelectedDMD] = useState({});
  const [selectedUrgence, setSelectedUrgence] = useState({});

  const typedmd = [
    { id: 1, name: 'Révision' },
    { id: 2, name: 'Accident' },
    { id: 3, name: 'Autre' },
  ];

  const typeurgence = [
    { id: 1, name: 'Priorité mission' },
    { id: 2, name: 'Urgent' },
    { id: 3, name: 'Normal' },
  ];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChangeVHL = (e) => {
    setSelectedVHL(e);
    const selectedVehicle = vehicles.find(
      (vehicle) => vehicle._id === e._id
    );
    if (selectedVehicle) {
      setIdVHL(selectedVehicle?._id || "");
      setService(selectedVehicle?.service || "");
      setVHL(selectedVehicle?.nomvhl || "");
      setImmat(selectedVehicle?.dossardorigine || "");
      setKm(selectedVehicle?.km || "");
      setImage(selectedVehicle?.image || "");
    }
  };

  const handleChangeKm = (e) => {
    setKm(e.target.value);
  };

  const handleChangeTypeDmd = (e) => {
    setTypeDmd(e.name);
    setSelectedDMD(e);
  };

  const handleChangeDegreUrgence = (e) => {
    setDegreUrgence(e.name);
    setSelectedUrgence(e);
  };

  const handleChangeNom = (e) => {
    setNom(e.target.value);
  };

  const handleChangeNTPH = (e) => {
    setNTPH(e.target.value);
  };

  const handleChangePositionCDB = (e) => {
    setPositionCDB(e.target.value);
  };

  const handleChangePositionVHL = (e) => {
    setPositionVHL(e.target.value);
  };

  const handleChangeDetails = (e) => {
    setDetails(e.target.value);
  };

  
  const onSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Mise à jour du véhicule avec la nouvelle position
      const updatedValue = { ...selectedVHL, position: "Garage" };
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/vhl/update/${idvhl}`, 
        updatedValue,
        {
          headers: authHeader(),
        }
      );
  
      // Création de la demande garage
      const dmdgarage = {
        idvhl,
        nomvhl,
        service,
        dossardorigine,
        km,
        image,
        nom,
        typedemande,
        degreurgence,
        nTPH,
        positioncdb,
        positionvhl,
        details,
        idusers: currentUser.username,
      };
  
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/dmdintervgarage/add`,
        dmdgarage,
        {
          headers: authHeader(),
        }
      );
  
      window.location = "/";
    } catch (error) {
      console.error(error);
    }
  };
  

  const filteredVehicles = vehicles.filter(
    (vehicles) => currentUser && vehicles.service === currentUser.service
  );

  const sortedVehicles = filteredVehicles.slice().sort((a, b) => a.nomvhl.localeCompare(b.nomvhl));


  return (
    <div>
    <h3 className="text-2xl font-bold text-center mb-6">Demande d'intervention Garage</h3>
      <form onSubmit={onSubmit}>
      <label htmlFor="vhl" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
        Véhicule
      </label>
      <Listbox value={selectedVHL} onChange={handleChangeVHL}>
  {({ open }) => (
    <div className="relative mt-2">
      <Listbox.Button
        className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
      >
        <span className="flex items-center">
          <img src={selectedVHL.image} alt="" className="h-8 w-8 flex-none rounded-full bg-gray-50 object-cover" />
          <span className="ml-5 block truncate font-bold">
            {selectedVHL.nomvhl} 
          </span>
          <span className="ml-4 block truncate italic">
          {selectedVHL.dossardorigine}
          </span>
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
          {open ? (
            <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </span>
      </Listbox.Button>

      <Transition
        show={open}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options
          className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          {sortedVehicles.map((vhl) => (
            <Listbox.Option
              key={vhl._id}
              className={({ active }) =>
                classNames(
                  active ? 'bg-blue-900 text-white' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-3 pr-9'
                )
              }
              value={vhl}
            >
              {({ selected, active }) => (
                <>
                  <div className="flex items-center">
                  <img src={vhl.image} alt="" className="h-8 w-8 flex-none rounded-full bg-gray-50 object-cover" />
                    <span className="ml-5 block truncate font-bold">
                      {vhl.nomvhl} 
                    </span>
                    <span className="ml-4 block truncate italic">
                      {vhl.dossardorigine}
                    </span>
                  </div>
                  {selected ? (
                    <span
                      className={classNames(
                        active ? 'text-white' : 'text-blue-900',
                        'absolute inset-y-0 right-0 flex items-center pr-4'
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </div>
  )}
</Listbox>

        
<div className="relative mt-5">
      <label
        htmlFor="km"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Kilométrage actuel
      </label>
      <input
        type="number"
        name="km"
        id="km"
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={km}
        onChange={handleChangeKm}
      />
    </div>

    <Listbox value={selectedDMD} onChange={handleChangeTypeDmd}>
      {({ open }) => (
        <>
          <Listbox.Label className="mt-5 block text-sm font-medium leading-6 text-gray-900">Type de demande</Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-4 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-900 sm:text-sm sm:leading-6">
              <span className="block truncate">{selectedDMD.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {typedmd.map((dmd) => (
                  <Listbox.Option
                    key={dmd.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-blue-900 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={dmd}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {dmd.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-900',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>

    <Listbox value={selectedUrgence} onChange={handleChangeDegreUrgence}>
      {({ open }) => (
        <>
          <Listbox.Label className="mt-5 block text-sm font-medium leading-6 text-gray-900">Degré d'urgence</Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-4 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-900 sm:text-sm sm:leading-6">
              <span className="block truncate">{selectedUrgence.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {typeurgence.map((dmd) => (
                  <Listbox.Option
                    key={dmd.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-blue-900 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={dmd}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {dmd.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-900',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>

    <div className="relative mt-5">
      <label
        htmlFor="nom"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Nom
      </label>
      <input
        type="text"
        name="nom"
        id="nom"
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={nom}
        onChange={handleChangeNom}
      />
    </div>

    <div className="relative mt-5">
      <label
        htmlFor="tph"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Numéro de TPH
      </label>
      <input
        type="text"
        name="tph"
        id="tph"
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={nTPH}
        onChange={handleChangeNTPH}
      />
    </div>


    <div className="relative mt-5">
      <label
        htmlFor="cdb"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Position du CDB
      </label>
      <input
        type="text"
        name="cdb"
        id="cdb"
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={positioncdb}
        onChange={handleChangePositionCDB}
      />
    </div>

    <div className="relative mt-5">
      <label
        htmlFor="vhl"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Localisation du VHL
      </label>
      <input
        type="text"
        name="vhl"
        id="vhl"
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={positionvhl}
        onChange={handleChangePositionVHL}
      />
    </div>

    <div className="relative mt-5">
  <label
    htmlFor="details"
    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
  >
    Détails
  </label>
  <div className="mt-2">
        <textarea
          rows={4}
          name="details"
          id="details"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
          placeholder={'Détails / Explications'}
          value={details}
          onChange={handleChangeDetails}
        />
      </div>
</div>


    <div className="form-group text-center">
  <button
    type="submit"
    className="mt-8 mx-auto rounded-lg bg-blue-900 px-3 py-2 text-base font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
  >
    Valider la demande
  </button>
</div>
      </form>
    </div>
  );
};

export default DmdGarage;