import React, { Fragment, useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react'
import "react-datepicker/dist/react-datepicker.css";
import { format, addMonths, subMonths, startOfMonth, parseISO, getDaysInMonth, getDay, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import authHeader from "../auth-header";
import Datepicker from "tailwind-datepicker-react";



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

function RDVVhl(props) {
   
    const [vhl, setVhl] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState({});
    const [displayedDate, setDisplayedDate] = useState(new Date()); // The currently displayed month and year
    const [selectedDay, setSelectedDay] = useState(null);
    const { id } = useParams();
    const [open, setOpen] = useState(false);
    const [selectedVhlInfo, setSelectedVhlInfo] = useState({});
    const [dateSelectedRdv, setDateSelectedRDV] = useState("");
    const [showDate, setShowDate] = useState(false);

    const cancelButtonRef = useRef(null)

    const handleDateClick = () => {
      setShowDate(true);
    };
  
    const options = {
      autoHide: true,
      todayBtn: false,
      clearBtn: false,
      maxDate: new Date("2030-01-01"),
      minDate: new Date("2023-01-01"),
      theme: {
        background: "bg-gray-50 dark:bg-gray-700",
        todayBtn: "",
        clearBtn: "",
        icons: "",
        text: "",
        disabledText: "",
        input: "",
        inputIcon: "",
        selected: "bg-gray-400"
      },
      icons: {
        prev: () => <span> Précédent </span>,
        next: () => <span>Suivant</span>,
      },
      datepickerClassNames: "top-0",
      defaultDate: new Date(),
      language: "fr",
      disabledDates: [],
      weekDays: ["Lu", "Mar", "Mer", "Jeu", "Ve", "Sa", "Di"],
      inputNameProp: "date",
      inputIdProp: "date",
      inputPlaceholderProp: "Selectionner la date",
      inputDateFormatProp: {
        day: "numeric",
        month: "long",
        year: "numeric",
      },
    };

    useEffect(() => {
        if (isLoading) {
          axios
            .get(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/", { headers: authHeader() })
            .then((response) => {
              setVhl(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
          setisLoading(false);
          axios
            .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", {
              headers: authHeader(),
            })
            .then((response) => {
              setCurrentUser(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });


    // Function to navigate to the previous month
  const goToPreviousMonth = () => {
    const newDisplayedDate = subMonths(displayedDate, 1);
    setDisplayedDate(newDisplayedDate);
  };

  // Function to navigate to the next month
  const goToNextMonth = () => {
    const newDisplayedDate = addMonths(displayedDate, 1);
    setDisplayedDate(newDisplayedDate);
  };

  // Générer les jours du mois actuel
  const daysInMonth = getDaysInMonth(displayedDate);
  const firstDayOfMonth = getDay(startOfMonth(displayedDate));

  const days = Array.from({ length: daysInMonth }, (_, i) => {
    const currentDate = new Date(displayedDate.getFullYear(), displayedDate.getMonth(), i + 1);
    const isCurrentMonth = isSameDay(currentDate, startOfMonth(displayedDate));
    return { date: currentDate, isCurrentMonth };
  });


  // Filtrer les rdv pour afficher uniquement ceux du jour sélectionné
 const rdvforSelectedDay = vhl.filter((vhl) => {
  return selectedDay ? isSameDay(new Date(vhl.daterdv), selectedDay) : true;
});

const deleteRDV = (id) => {
  axios
        .delete(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/" + id, { headers: authHeader() })
        .then(() => window.location = '/rdvvhl')
        .catch((error) => {
          console.log(error);
        });
};

const openModalHandler = (vhl) => {
  setSelectedVhlInfo({
    image: vhl.image,
    nomvhl: vhl.nomvhl,
    dossardorigine: vhl.dossardorigine,
    service : vhl.service,
    id: vhl._id,
  });
  console.log(selectedVhlInfo.id);
  setOpen(true);
};

const onChangeDateSelectedRDV = (date) => {
  setDateSelectedRDV(date);
};

const Valider = () => {
  const rdv = {
    daterdv: dateSelectedRdv,
    idusers: currentUser.username,
  };
  axios
    .post(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/update/" + selectedVhlInfo.id, rdv, {
      headers: authHeader(),
    })
    .then((res) => {
      setOpen(false);
      window.location = "/rdvvhl"; 
    })
    .catch((error) => {
      console.log(error);
    });
};

  return (
<div>

<Transition.Root show={open} as={Fragment}>
<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <PencilSquareIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl font-bold leading-6 text-gray-900">
                      Modifier la date du RDV :
                    </Dialog.Title>
                    <div className="mt-4 flex items-center justify-center">
                    <div className="h-11 w-11 flex-shrink-0">
                          <img className="h-10 w-10 flex-none rounded-full bg-gray-50 object-cover" src={selectedVhlInfo.image} alt="" />
                        </div>
                        <div className="ml-4">
                          <div className="font-bold text-gray-900">{selectedVhlInfo.nomvhl}</div>
                          <div className="mt-1 text-gray-500">{selectedVhlInfo.dossardorigine}</div>
                          <div className="mt-1 text-gray-500 italic">{selectedVhlInfo.service}</div>
                        </div>
                      </div>
                      <div className="mt-4">
                      <Datepicker 
                        options={options} 
                        value={selectedVhlInfo.daterdv} 
                        onChange={(date) => onChangeDateSelectedRDV(date)} 
                        show={showDate} 
                        setShow={setShowDate} 
                        onClick={handleDateClick}
                        />
                        </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={Valider}
                    >
                    Valider
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog></Transition.Root>

      <h2 className="text-base text-center font-semibold leading-6 text-gray-900">RDV Garage VHL</h2>
      <div className="">
        <div className="max-w-lg mx-auto mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
          <div className="flex items-center text-gray-900">
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={goToPreviousMonth}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold">
              {format(displayedDate, 'LLLL yyyy')}
            </div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={goToNextMonth}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>D</div>
            <div>L</div>
            <div>M</div>
            <div>M</div>
            <div>J</div>
            <div>V</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {Array(firstDayOfMonth)
              .fill(null)
              .map((_, idx) => (
                <button key={`prev-${idx}`} type="button" className="py-1.5"></button>
              ))}
            {days.map((day, dayIdx) => (
              <button
                key={day.date.toISOString()}
                type="button"
                className={classNames(
                  'py-1.5 hover:bg-gray-100 focus:z-10',
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                  (day.isSelected || isSameDay(day.date, selectedDay)) && 'font-semibold',
                  day.isSelected && 'text-white',
                  !day.isSelected && day.isCurrentMonth && !isSameDay(day.date, selectedDay) && 'text-gray-900',
                  !day.isSelected && !day.isCurrentMonth && !isSameDay(day.date, selectedDay) && 'text-gray-400',
                  isSameDay(day.date, new Date()) && !day.isSelected && 'text-indigo-600',
                  dayIdx === 0 && 'rounded-tl-lg',
                  dayIdx === 6 && 'rounded-tr-lg',
                  dayIdx === days.length - 7 && 'rounded-bl-lg',
                  dayIdx === days.length - 1 && 'rounded-br-lg'
                )}
                onClick={() => setSelectedDay(day.date)}
              >
                <time
                  dateTime={day.date.toISOString()}
                  className={classNames(
                    'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                    day.isSelected && isSameDay(day.date, new Date()) && 'bg-indigo-600',
                    day.isSelected && !isSameDay(day.date, new Date()) && 'bg-gray-900'
                  )}
                >
                  {day.date.getDate()}
                </time>
              </button>
            ))}
          </div>
          <button
            type="button"
            onClick={() => (window.location.href = "/newrdvvhl")}
            className="mt-8 w-full rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Créer un RDV VHL
          </button>
        </div>

        <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto flex items-center">
        <h1 className="mt-6 text-base font-semibold leading-6 text-gray-900">RDV du {selectedDay ? format(selectedDay, 'dd LLLL yyyy', { locale: fr }) : ''}</h1>
      </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Véhicule
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Service
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Type de RDV
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Remarques / Détails
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Voir</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {rdvforSelectedDay.map((vhl) => (
                  <tr key={vhl._id}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <div className="flex items-center">
                        <div className="h-11 w-11 flex-shrink-0">
                          <img className="h-10 w-10 flex-none rounded-full bg-gray-50 object-cover" src={vhl.image} alt="" />
                        </div>
                        <div className="ml-4">
                          <div className="font-bold text-gray-900">{vhl.nomvhl}</div>
                          <div className="mt-1 text-gray-500">{vhl.dossardorigine}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">{vhl.service}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div className="text-gray-900">{vhl.typerdv}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div className="text-gray-900">{vhl.remarque}</div>
                    </td>
                    
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <div className='flex flex-col items-center'>
                    <a onClick={() => openModalHandler(vhl)} className="text-indigo-600 hover:text-indigo-900 mb-2">
                      Modifier<span className="sr-only">, {vhl.nomvhl}</span>
                    </a>
                      <a
                        onClick={() => {
                          if (window.confirm('Êtes-vous sûr de vouloir supprimer cette tâche ?')) {
                            deleteRDV(vhl._id);
                          }
                        }}
                        className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-red-700 focus:outline-none"
                      >
                        Supprimer<span className="sr-only">, {vhl.nomvhl}</span>
                      </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  )
}

export default RDVVhl;
