import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import authHeader from "../auth-header";
import { CheckIcon, PlusCircleIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function HistoRevision() {
  const [histovhl, setHistoVhl] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [vhl, setVhl] = useState([]);

  const revisions = [];
  for (let km = 10000; km <= 360000; km += 10000) {
    revisions.push({ title: `Révision des ${km}km`, km, etat: 'grayed', remarque: '', kmeffectif: '', date: '' });
  }
  
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/historiquerevision/" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        setHistoVhl(response.data);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
      .then((response) => {
        setVhl(response.data);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      });
  }, []);

  // Parcourez les révisions pour définir leur état en fonction des données de la base de données
  revisions.forEach((revision) => {
    const matchingEntry = histovhl.find((entry) => entry.km === revision.km);
    if (matchingEntry) {
      // Une révision a été effectuée pour ce kilométrage
      revision.etat = 'complete';
      revision.remarque = matchingEntry.remarque;
      revision.kmeffectif = matchingEntry.kmeffectif;
      revision.date = matchingEntry.date;
    }
  });

  // Trouver l'index de la dernière révision effectuée
  let lastCompletedIndex = -1;
  for (let i = revisions.length - 1; i >= 0; i--) {
    if (revisions[i].etat === 'complete') {
      lastCompletedIndex = i;
      break;
    }
  }

   // Trouver l'index de la première révision complète
   let firstCompletedIndex = -1;
   for (let i = 0; i < revisions.length; i++) {
     if (revisions[i].etat === 'complete') {
       firstCompletedIndex = i;
       break;
     }
   }

   // Si il y a une révision complète, ignorer les révisions avant la première complète et garder les suivantes
  if (firstCompletedIndex > -1) {
    for (let i = 0; i < firstCompletedIndex; i++) {
      revisions[i].etat = 'ignored';
    }
    for (let i = lastCompletedIndex + 1; i < revisions.length; i++) {
      revisions[i].etat = 'gray';
    }
  }
    // Définir l'état "upcoming" pour la révision suivante
    if (lastCompletedIndex >= 0 && lastCompletedIndex < revisions.length - 1) {
      revisions[lastCompletedIndex + 1].etat = 'upcoming';
    }

  // Filtrer les révisions pour n'inclure que celles complètes et futures
  const filteredRevisions = revisions.filter((revision, index) => {
    if (histovhl.length > 0) {
      return revision.etat === 'complete' || revision.etat === 'upcoming' || revision.etat === 'gray';
    } else {
      return true; 
    }
  });

  const fabStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
  };

  return (
    <div>
      <nav aria-label="Progress">
        <ol role="list" className="overflow-hidden ml-10 mt-10">
          {filteredRevisions.map((revision, revisionID) => (
            <li key={revision.title} className={classNames(revisionID !== filteredRevisions.length - 1 ? 'pb-10' : '', 'relative')}>
              {revision.etat === 'complete' ? (
                <>
                  {revisionID !== filteredRevisions.length - 1 ? (
                    <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600" aria-hidden="true" />
                  ) : null}
                  <a className="group relative flex items-start">
                    <span className="flex h-9 items-center">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                        <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium">{revision.title}</span>
                      <span className="text-sm text-gray-500"> Révision effectuée le {new Date(revision.date).toLocaleDateString()}</span>
                      <span className="text-sm text-gray-500"> Kilométrage : {revision.kmeffectif}</span>
                      <span className="text-sm text-gray-500"> Remarques : {revision.remarque}</span>
                    </span>
                  </a>
                </>
              ) : revision.etat === 'upcoming' ? (
                <>
                  {revisionID !== filteredRevisions.length - 1 ? (
                    <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                  ) : null}
                  <a className="group relative flex items-start" aria-current="step">
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                        <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-indigo-600">{revision.title}</span>
                      <span className="text-sm text-gray-500"> Prochaine révision</span>
                    </span>
                  </a>
                </>
              ) : (
                <>
                  {revisionID !== filteredRevisions.length - 1 ? (
                    <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                  ) : null}
                  <a className="group relative flex items-start">
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                        <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-gray-500">{revision.title}</span>
                      <span className="text-sm text-gray-500"> Révision future</span>
                    </span>
                  </a>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
      {currentUser.roles === "Admin" && (

<div className="fixed bottom-6 right-6">
<button
  type="button"
  onClick={() => (window.location.href =`/newrevision/${vhl._id}`)}
  className="rounded-full bg-indigo-900 p-2 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
>
  <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
  
</button>
</div>

      )}
    </div>
  );
}
